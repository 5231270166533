import React from 'react';
import { Box, VStack, HStack, Text, Image, Link as ChakraLink, Container } from '@chakra-ui/react';
import yeartheme from './yeartheme2024.JPG';
import biblestudy from './biblestudy.png';
import bibleholding from './bibleholding.jpeg';
import blackfamilypraying from './black-family-praying.jpg';
import youthImage1 from './youthImage1.jpg'
import YouTubePlayer from './YouTubePlayer';
import { db } from '../../firebase/firebase'; // Ensure this path is correct
import { collection, orderBy, query, getDocs, where, limit } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Home = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToSection) {
      const section = document.getElementById(location.state.scrollToSection);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <Container maxW="container.xl" py={10}>
      <WelcomeSection />
      <UpcomingEventsSection id="upcoming_events"/>
      <OurServicesSection id="weekly_activities"/>
      <RecentServiceSection id="recent_service"/>
    </Container>
  );
};

const WelcomeSection = () => (
  <Box
    bg="gray.50" // Light background for better contrast
    p={[6, 10]} // Increase padding for better spacing
    boxShadow="2xl" // Stronger shadow for a more elevated look
    borderRadius="lg"
    mb={8} // Increase bottom margin for spacing from other sections
    textAlign="center" // Center text on smaller screens
  >
    <HStack
      spacing={10}
      align="center"
      justify="center"
      wrap="wrap-reverse" // Wrap content on smaller screens, reverse the order
      flexDirection={{ base: "column-reverse", md: "row" }} // Stack on mobile, row on desktop
    >
      <Image
        src={yeartheme}
        alt="theme"
        boxSize={{ base: "80%", md: "300px" }} // Adapt image size for mobile and desktop
        objectFit="cover"
        borderRadius="full"
        boxShadow="md" // Add shadow to the image for depth
        mb={{ base: 6, md: 0 }} // Add margin on mobile to separate the image and text
      />
      <VStack align={{ base: "center", md: "start" }} maxW="600px" spacing={4}>
        <Text as="h1" fontSize={["2xl", "3xl"]} fontWeight="bold" color="blue.600">
          Welcome to FOLCI (Fountain Of Life Church Int'l)
        </Text>
        <Text fontSize="lg" color="gray.700" textAlign={{ base: "center", md: "left" }}>
          Welcome to Fountain of Life Church Int'l, a vibrant Christian church located in Romford, Essex. We invite you to join our family-friendly Sunday services, held every week at 10 AM. Our church is easily accessible from Romford Station, with ample parking nearby.
        </Text>
        <Text fontSize="lg" color="gray.700" textAlign={{ base: "center", md: "left" }}>
          We offer a variety of community services, including Bible studies, youth groups, and outreach programs. Whether you're new to the area or looking for a church in Romford, we warmly welcome you to worship with us. Check out our upcoming events or visit our location in the heart of Romford, just minutes from Queens Hospital.
        </Text>
      </VStack>
    </HStack>
  </Box>
);


const UpcomingEventsSection = ({ id }) => {
  const [eventsToShow, setEventsToShow] = useState([]);

  useEffect(() => {
    const fetchEventsToShowInHome = async () => {
      const q = query(
        collection(db, 'events'),
        where('showInHome', '==', true),
        orderBy('createdAt', 'desc'),
        limit(3)
      );
      const querySnapshot = await getDocs(q);
      const fetchedEvents = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setEventsToShow(fetchedEvents);
    };

    fetchEventsToShowInHome();
  }, []);

  return (
    <Box
      id={id}
      bg="blue.600"
      p={[6, 10]} // Increase padding for better spacing
      boxShadow="xl"
      borderRadius="lg"
      color="white"
      mb={8} // Add more margin to separate from other sections
      textAlign="center"
    >
      <VStack spacing={6}>
        <Text as="h1" fontSize={["2xl", "4xl"]} fontWeight="bold">
          Upcoming Events
        </Text>

        {eventsToShow.length > 0 ? (
          eventsToShow.map(event => (
            <HStack
              key={event.id}
              spacing={6}
              align="center"
              justify="space-between"
              w="full"
              textAlign="left"
              bg="white"
              color="gray.800"
              p={5}
              borderRadius="lg"
              boxShadow="md"
              _hover={{ boxShadow: "lg", transform: "scale(1.02)" }} // Add hover effect
              transition="all 0.3s ease-in-out"
            >
              <VStack align="start" spacing={2} w={["full", "70%"]}>
                <Text as="h2" fontSize={["xl", "2xl"]} fontWeight="bold">
                  {event.title}
                </Text>
                <Text fontSize={["sm", "md"]}>
                  {event.message}
                </Text>
              </VStack>

              {event.imageUrl && (
                <Image
                  src={event.imageUrl}
                  alt={event.title}
                  boxSize={["100px", "150px"]} // More flexible for responsiveness
                  borderRadius="md"
                  objectFit="cover"
                  boxShadow="md"
                />
              )}
            </HStack>
          ))
        ) : (
          <Text fontSize="lg" color="gray.200">
            No upcoming events to display
          </Text>
        )}
      </VStack>
    </Box>
  );
};




const OurServicesSection = ({id}) => (
  <VStack id={id} spacing={5} p={8} bg="white" color="gray.600" boxShadow="xl" borderRadius="lg" mb={5}>
    <Text as="h1" fontSize="3xl" fontWeight="bold" color="blue.600" textAlign="center">Our Services</Text>
    <HStack spacing={4} wrap="wrap" justify="center">
      <ServiceCard title="Wednesday Services on Zoom" imageSrc={biblestudy} text="WEDNESDAYS we have our Bible Study on zoom from 8pm to 9pm" link="https://us02web.zoom.us/j/81148930003?pwd=aFFrV0VEam9Fam1ZZ1l6MHdQUHpwUT09#success" />
      <ServiceCard title="Friday Services" imageSrc={bibleholding} text="First Friday of the Month we have our prayer and deliverance service from 7:30pm-9pm. Last Friday of each Month is our Half-night from 8pm to 11pm." />
      <ServiceCard title="Sunday Family Service" imageSrc={blackfamilypraying} text="SUNDAYS we have our family services 10am -11:30am. The first Sunday of every month is our Communion service from 10am to 12pm." link="https://www.youtube.com/@folci" />
      <ServiceCard title="Youth Service" imageSrc={youthImage1} text="Join us every Sunday from 12 PM." link="/Youth" />
    </HStack>
  </VStack>
);

const RecentServiceSection = ({ id }) => (
  <Box
    id={id}
    bg="blue.600" // A slightly darker blue for more contrast
    color="white"
    p={[6, 10]} // Add more padding for better spacing
    boxShadow="xl"
    borderRadius="lg"
    textAlign="center"
    maxW="800px" // Limit the max width for better readability
    m="auto" // Center the box horizontally
    mt={8} // Add margin to the top for spacing
    mb={8} // Add margin to the bottom for spacing
    _hover={{ boxShadow: "2xl", transform: "scale(1.02)" }} // Subtle hover effect
    transition="all 0.3s ease-in-out"
  >
    <Text as="h1" fontSize={["2xl", "3xl"]} fontWeight="bold" mb={6}>
      Watch Our Recent Service
    </Text>
    <Box
      maxW="full"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg" // Add a shadow to the video container for better depth
      mb={4}
    >
      <YouTubePlayer />
    </Box>
  </Box>
);

  
const ServiceCard = ({ title, imageSrc, text, link }) => (
<VStack
  bg="blue.100"              // Change background to white for a cleaner look
  p={6}                   // Increase padding for more spacious layout
  boxShadow="lg"          // Make the shadow stronger
  borderRadius="lg"       // Use larger border radius for smoother corners
  maxW="250px"            // Increase max width for better content display
  textAlign="center"      // Center the text inside the card
  _hover={{
    boxShadow: "xl",      // Make the hover shadow more prominent
    transform: "scale(1.05)", // Slightly enlarge the card on hover
    transition: "all 0.3s ease-in-out" // Smooth hover transition
  }}
  transition="all 0.3s ease-in-out" // Smooth transition for non-hover state
>
  <Image
    src={imageSrc}
    alt={title}
    boxSize="120px"        // Increase the image size slightly for prominence
    objectFit="cover"
    borderRadius="full"
    boxShadow="md"         // Add shadow to the image for depth
    mb={4}                 // Add margin to separate the image from the text
  />
  <Text as="h2" fontSize="lg" fontWeight="semibold" color="blue.600" mb={2}>
    {title}
  </Text>
  <Text fontSize="sm" color="gray.600" mb={4}>
    {text}
  </Text>
  {link && (
    <ChakraLink
      href={link}
      isExternal
      color="blue.500"
      fontWeight="bold"
      _hover={{ textDecoration: "underline" }}
    >
      Learn More
    </ChakraLink>
  )}
</VStack>

);

export default Home;
