import { React, useEffect } from 'react';
import { Box, VStack, HStack, Text, Image, useClipboard, Container, IconButton } from '@chakra-ui/react';
import pastorHenry from './pastorHenry.jpg';
import pastorSteve from './pastorSteve.jpg';
import pastorEddie from './pastorEddie.jpg';
import { FaCopy } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const About = () => {

    const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToSection) {
      const section = document.getElementById(location.state.scrollToSection);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);


    const accName = "Fountain of life church Int'l";
    const sortCode = "20-53-00";
    const accNum = "60846732";
    const { hasCopied: hasCopiedAccName, onCopy: onCopyAccName } = useClipboard(accName);
    const { hasCopied: hasCopiedSortCode, onCopy: onCopySortCode } = useClipboard(sortCode);
    const { hasCopied: hasCopiedAccNum, onCopy: onCopyAccNum } = useClipboard(accNum);

    return (
        <Container maxW="container.xl" py={10}>
            <VStack spacing={10} align="stretch">
                <VisionSection />
                <TeamSection />
                <GospelSection />
                <DonationsSection             
                    id="donations"
                    accName={accName} onCopyAccName={onCopyAccName} hasCopiedAccName={hasCopiedAccName}
                    sortCode={sortCode} onCopySortCode={onCopySortCode} hasCopiedSortCode={hasCopiedSortCode}
                    accNum={accNum} onCopyAccNum={onCopyAccNum} hasCopiedAccNum={hasCopiedAccNum}
                />
            </VStack>
        </Container>
    );
};

const VisionSection = () => (
        <Box
          bg="blue.50" // Light blue background for better contrast
          p={10} // Increase padding for a more spacious layout
          borderRadius="lg"
          boxShadow="xl" // Stronger box shadow for depth
          textAlign="center" // Center the text horizontally
          maxW="800px" // Limit max width to keep the content centered and readable
          m="auto" // Auto margin to center the box horizontally
          mt={10} // Top margin to add space above the box
          _hover={{ transform: "scale(1.05)", transition: "all 0.3s ease-in-out" }} // Subtle hover effect for interactivity
        >
          <Text fontSize="3xl" fontWeight="bold" mb={6} color="blue.600">
            Our Vision for the Community
          </Text>
          <VStack align="center" spacing={5}>
            <Text as="h2" fontSize="xl" fontWeight="bold" color="gray.800">
              Our Church's Vision for Romford and the Community
            </Text>
            <Text as="p" fontSize="md" color="gray.700" maxW="600px">
              At Fountain of Life Church in Romford, we are committed to promoting health and prosperity for the local community. Our church believes in creating a nurturing environment where individuals and families can thrive spiritually, emotionally, and physically.
            </Text>
            <Text as="p" fontSize="md" color="gray.700" maxW="600px">
              Fountain of Life Church is dedicated to the growth and prosperity of local businesses and families in Romford. Through our various outreach programs, we empower families and provide support to small businesses, fostering a thriving community.
            </Text>
          </VStack>
        </Box>
);

const TeamSection = () => (
    <VStack spacing={10} textAlign="center" mt={10} mb={10}>
      <Text fontSize="3xl" fontWeight="bold" color="blue.600">
        Meet the Pastoral Team
      </Text>
      <HStack spacing={8} wrap="wrap" justify="center">
        <TeamMember name="Founder: Rev. Henry Nsiah Anning" image={pastorHenry} />
        <TeamMember name="Senior Pastor: Rev. Stephen Anning" image={pastorSteve} />
        <TeamMember name="Residential Pastor: Evang. Edward Anning" image={pastorEddie} />
      </HStack>
    </VStack>
  );
  

  const TeamMember = ({ name, image }) => (
    <VStack
      bg="white"
      p={6} // Increase padding for more space inside the card
      borderRadius="lg"
      boxShadow="lg"
      _hover={{
        boxShadow: "xl",
        transform: "translateY(-5px)", // Add a subtle lift effect on hover
      }}
      transition="all 0.3s ease-in-out"
      maxW="260px"  // Increase max width for a more balanced look
      textAlign="center"
    >
      <Image
        boxSize="140px" // Increase image size for better visual impact
        borderRadius="full"
        src={image}
        alt={name}
        boxShadow="md"  // Add shadow to the image for depth
        mb={4}          // Add more spacing below the image
      />
      <Text fontWeight="bold" fontSize="lg" color="gray.800">
        {name}
      </Text>
    </VStack>
  );
  

const DonationsSection = ({
    accName,
    onCopyAccName,
    hasCopiedAccName,
    sortCode,
    onCopySortCode,
    hasCopiedSortCode,
    accNum,
    onCopyAccNum,
    hasCopiedAccNum,
    id,
  }) => (
    <Box
      id={id}
      bg="blue.50"
      p={10} // More padding for better spacing
      borderRadius="lg"
      boxShadow="xl"
      textAlign="center" // Center the entire section
      maxW="700px"
      m="auto"
      mt={10} // Add space above and below the section
    >
      <Text fontSize="3xl" fontWeight="bold" color="blue.700" mb={6}>
        Donations
      </Text>
      <Text fontSize="lg" color="gray.700">To Fountain of Life Church Int'l:</Text>
      <Text fontSize="lg" color="gray.700" mb={4}>Barclays Bank</Text>
      <DonationItem
        label="Account Name"
        value={accName}
        onCopy={onCopyAccName}
        hasCopied={hasCopiedAccName}
      />
      <DonationItem
        label="Sort Code"
        value={sortCode}
        onCopy={onCopySortCode}
        hasCopied={hasCopiedSortCode}
      />
      <DonationItem
        label="Account No"
        value={accNum}
        onCopy={onCopyAccNum}
        hasCopied={hasCopiedAccNum}
      />
    </Box>
  );
  
  const DonationItem = ({ label, value, onCopy, hasCopied }) => (
    <HStack spacing={2} justify="center" mb={2}>
      <Text fontSize="md" fontWeight="semibold">{label}: {value}</Text>
      <IconButton
        icon={<FaCopy />}
        size="sm"
        onClick={onCopy}
        aria-label={`Copy ${label.toLowerCase()}`}
        isRound
      />
      {hasCopied && <Text fontSize="sm" color="green.500">Copied!</Text>}
    </HStack>
  );
  
  const GospelSection = () => (
    <Box
      bg="white"
      p={10}
      borderRadius="lg"
      boxShadow="xl"
      textAlign="center"
      maxW="800px"
      m="auto"
      mt={10}
      mb={10}
    >
      <Text fontSize="3xl" fontWeight="bold" color="blue.700" mb={6}>
        The GOSPEL
      </Text>
      <Text fontSize="lg" color="gray.700" mb={4}>
        To the reader, know that God loves you! Out of God's love, He created every man, woman, and child on earth to fulfill a unique purpose. You cannot take advantage of what God has for you unless you know Him.
      </Text>
      <Text fontSize="lg" color="gray.700" mb={4}>
        God already knows you and wants you to acknowledge Him so you can enter into a relationship with Him. God so loved you that He sent His son Jesus to die for your sins so that you can have the forgiveness of sins and eternal life.
      </Text>
      <Text fontSize="lg" fontStyle="italic" color="gray.600" mb={4}>
        Today you can become a child of God, free from shame, guilt, oppression, and with all your sins forgiven. All you need to do is ask God.
      </Text>
      <Text fontSize="md" fontStyle="italic" color="gray.800" mb={4}>
        Say This Prayer: "God, I ask you to be my father and take me as your child. I believe you sent Jesus Christ to die on the cross, who rose again so that I can be forgiven of all my sins. From today, be my Lord, my Savior, and live in me. Amen."
      </Text>
    </Box>
  );
  


export default About;
