import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/authContext';
import Layout from './Components/Layout';
import Home from './Components/Home';
import Contact from './Components/Contact';
import About from './Components/About';
import ErrorPage from './ErrorPage';
import Admin from './Components/Admin';
import Login from './Components/Auth/Login';
import RequireAuth from './RequireAuth'; 
import Streams from './Components/Streams';


function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="youth" element={<Streams />} />
          <Route path="login" element={<Login />} />
          <Route 
            path="admin" 
            element={
              <RequireAuth>
                <Admin />
              </RequireAuth>
            } 
          />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
