import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  IconButton,
  useColorModeValue,
  Stack,
  Button,
  Flex,
  Image,
  Link,
  Icon,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';
import youthImage1 from './youthImage1.jpg';
import youthImage2 from './youthImage2.jpg';
import youthImage3 from './youthImage3.jpg';
import streamsLogo from './streamslogo.png'; 

const Carousel = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideInterval = useRef();

  useEffect(() => {
    slideInterval.current = setInterval(() => {
      setCurrentSlide((slideIndex) => (slideIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(slideInterval.current);
  }, [images.length]);

  const changeSlide = (direction) => {
    clearInterval(slideInterval.current);
    setCurrentSlide((slideIndex) => {
      const newIndex = direction === 'prev' ? slideIndex - 1 : slideIndex + 1;
      return (newIndex + images.length) % images.length;
    });
  };

  return (
    <VStack>
      <Flex width="full" justifyContent="center" alignItems="center" position="relative">
        <IconButton
          aria-label="Previous slide"
          icon={<ChevronLeftIcon />}
          onClick={() => changeSlide('prev')}
          position="absolute"
          left="0"
          zIndex="2"
          variant="solid"
          backgroundColor="rgba(255, 255, 255, 0.6)"
          opacity="0.9"
          _hover={{
            bg: "rgba(255, 255, 255, 0.8)",
          }}
          size="lg"
        />
        <Box
          boxSize="full"
          maxW={{ base: "full", md: "600px", lg: "900px" }}
          borderRadius="md"
          overflow="hidden"
        >
          <Image
            src={images[currentSlide]}
            alt={`Youth Ministry Event - Slide ${currentSlide + 1}`} // Meaningful alt text
            width="100%"
            objectFit="cover"
            transition="all 0.5s ease-in-out"
          />
        </Box>
        <IconButton
          aria-label="Next slide"
          icon={<ChevronRightIcon />}
          onClick={() => changeSlide('next')}
          position="absolute"
          right="0"
          zIndex="2"
          variant="solid"
          backgroundColor="rgba(255, 255, 255, 0.6)"
          opacity="0.9"
          _hover={{
            bg: "rgba(255, 255, 255, 0.8)",
          }}
          size="lg"
        />
      </Flex>
    </VStack>
  );
};

const Streams = () => {
  const bg1 = useColorModeValue("white", "blue.900");
  const bg2 = useColorModeValue("gray.900", "black");
  const textColor = useColorModeValue("black.700", "gray.200");
  const headingColor = useColorModeValue("black", "white");
  const highlightColor = useColorModeValue("cyan.400", "orange.400");
  const youthImages = [youthImage1, youthImage2, youthImage3];

  return (
    <Stack spacing={10} align="center">
      {/* Welcome Section */}
      <Box
        bg={bg1}
        p={[6, 10]}
        boxShadow="2xl"
        borderRadius="lg"
        textAlign="center"
        color={textColor}
        mb={5}
        mt={5}
      >
        <Image 
          src={streamsLogo} 
          alt="FOLCI Streams Youth Ministry Logo" // Meaningful alt text for SEO
          boxSize={["120px", "150px"]} 
          objectFit="contain"
          mb={4}
        />
        <Heading as="h1" size="2xl" color={headingColor} mb={4}>
          Welcome to Our Youth Church
        </Heading>
        <Text fontSize="2xl" fontWeight="bold" color={highlightColor} mb={4}>
          Raise, Equip, Empower
        </Text>
        <Text fontSize="lg" mt={2}>
          Join us every Sunday at 12 PM, located at 248 London Road RM7 9EL.
        </Text>
      </Box>

      {/* Carousel and Call to Action Section */}
      <Box
        bg={bg2}
        p={8}
        boxShadow="2xl"
        borderRadius="lg"
        color={textColor}
        mb={5}
      >
        <Flex 
          direction={{ base: "column", md: "row" }} 
          align="center"
          justify="space-between"
        >
          {/* Carousel */}
          <Box width="full" maxW={{ base: "90%", md: "500px" }} overflow="hidden" borderRadius="lg" mb={{ base: 5, md: 0 }}>
            <Carousel images={youthImages} />
          </Box>
          
          {/* Text Section */}
          <VStack spacing={5} maxW={{ base: "90%", md: "500px" }} textAlign={{ base: "center", md: "left" }}>
            <Heading as="h2" size="lg" color={headingColor}>
              FOLCI Youth Ministry
            </Heading>
            <Text>
              Dive into a vibrant community where faith meets action, friendships flourish, and futures are forged with purpose. Join FOLCI Streams for an unforgettable journey of spiritual growth, leadership development, and fun.
            </Text>
            <Button as={RouterLink} to="/contact" colorScheme="orange" size="lg" _hover={{ transform: "scale(1.05)", transition: "all 0.3s ease-in-out" }}>
              Join Us Now
            </Button>
            <Link 
              href="https://www.instagram.com/streamsfolci?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" 
              aria-label="Instagram - Follow FOLCI Streams Youth Ministry" 
              _hover={{ color: 'pink.400', transform: 'scale(1.1)' }} 
              isExternal
            >
              <Icon as={FaInstagram} boxSize={8} />
            </Link>
          </VStack>
        </Flex>
      </Box>
    </Stack>
  );
};

export default Streams;
